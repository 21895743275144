.header{ 
    position: relative;
}
.nav-wrapper .container{ 
    flex-direction: row;
    flex-wrap: nowrap;
}
.nav-wrapper.sticky-nav{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 6;
    /* transition: all 0.1s ease-in-out; */
}

.bottom-margin{
    margin-bottom: 110px;
} 

.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction:  row;

}

.header-container .top-bar-item{
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    gap: 5px;
}
.top-bar-item.socials{
    gap: 16px;
    font-size: 2rem;
    font-weight: bold;
}

