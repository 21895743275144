.right-image-section{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content:center;
    gap: 20px;
}

.right-image-section .section-image{
    width: 100%;
}


@media (min-width: 768px) {
    .right-image-section{
        grid-template-columns: 2fr 1fr;
    }
    .right-image-section-title{ 
        text-align: left;
    }
}