.details-form{
    padding: 20px;
}


.details-form .form-group{
    display: flex;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 10px;
}

.details-form .form-group input{
    border-bottom: 1px solid var(--sonic-silver);
    color: inherit;
    margin-top: auto;
}
.details-form input[type="submit"]{
    background-color: var(--emerald);
    color: white;
    padding: 6px 10px;
    cursor: pointer;
    
}
.details-form input[type="submit"]:hover{
    filter: brightness(80%);
}

.details-form #update-success{
    background-color: var(--pantone-green);
    filter: brightness(80%);
    cursor: wait ;

}

.complete-order-form input[type="submit"]{
    background-color: var(--pantone-green);
    color: white;
    cursor: pointer;
}
.complete-order-form p{
    font-size: 13px;
    text-align: center;
}
.complete-order-form input[type="submit"]:hover{
    filter: brightness(80%);
}

input.completing-order {
    filter: brightness(80%);
}
input.completing-order:hover{
    cursor: wait;
}