.section .visit-us{ 
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}
.section .contact-details{
    margin-top: 20px;
}
.contact-list-title{
    font-weight: bold;
}
.contact-list-item{ 
    margin-left: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
}
.contact-list-item a:hover{
    color: var(--emerald)
}



@media (min-width: 768px){

    .section .visit-us{ 
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}
