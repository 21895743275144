.checkout-container{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    gap: 20px;
    justify-content: space-around;
    color: var(--sonic-silver);
}
.checkout-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: max-content;
}

.checkout-item{ 
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items:center;
    gap: 8px;
    flex-wrap: nowrap;
    padding: 5px;
    margin: 5px 20px;
    border: 1px solid grey;
}
.chekout-remove-item-btn{
    font-size: 3rem;
    margin-left: auto;
}
.chekout-remove-item-btn:hover{
    color:var(--emerald)
}

.checkout-container .checkout-cart,
.checkout-container .checkout-payment{
    background-color: var(--mint-cream);
    min-width: 200px;
}


.checkout-payment-title{
    background-color: var(--pantone-green);
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;

}
.checkout-card-title{
    font-weight: bold;
    font-size: var(--fs-4)
}

/* payment methods */

.checkout-padding{
    padding: 20px;
    line-height: 2;
}
.payment-methods{
    display: flex;
   gap: 16px;
    align-items: center;

    flex-wrap: wrap;
}
.payment-method-form-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: max-content;
    justify-content:center;

    gap: 10px;
}
.payment-method-form-group>input, .payment-method-form-group>label{
    display: inline;
    width: unset;
    cursor: pointer;
}


.checkout-download-btn{
    font-size: var(--fs-5);
    padding-top: 10px;
    padding-bottom: 10px;
}

/*  PAYMENT CARDS */

.mpesa-card P>span{
    display: inline;
    font-weight: bold;
}
.mpesa-verify-form{
    border: 1px solid var(--sonic-silver);
    display: flex;
}
.mpesa-verify-input{
    text-align: center;
    background-color: white;
    color: inherit;
}
.mpesa-verify-btn{
    background-color: green;
    color: white;
    font-size: var(--fs-5);
    
}
.mpesa-verify-btn:hover{
    filter: brightness(80%)
}

.personal-details{
    padding: 20px;
}
.details-title{
    font-size: var(--fs-3);
    font-weight: bold;
    color: var(--gunmetal);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

/* media queries */
@media (min-width: 768px) {

    .checkout-container{
        flex-direction: row;
        flex-wrap: nowrap;
    }
}