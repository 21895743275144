.left-image-section{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content:center;
    gap: 16px;
}

.left-image-section .section-image{
    width: 100%;
}

@media (min-width: 768px) {
    .left-image-section{
        grid-template-columns: 1fr 2fr;
    }
    
    .left-image-section  .p{
        text-align: left;
    }
}