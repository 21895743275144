/*-----------------------------------*\
  #home.css
\*-----------------------------------*/

/*-----------------------------------*\
  #HERO
\*-----------------------------------*/



.hero-banner { display: none; }

.hero {
  background-image: url(../images/hero-left-bg.png);
  background-color: var(--mint-cream);
  background-repeat: no-repeat;
  background-position: center right;
  padding-block: 80px;
}

.hero-subtitle {
  color: var(--emerald);
  font-family: var(--ff-playfair-display);
  font-weight: var(--fw-700);
  font-style: italic;
  letter-spacing: 2px;
  margin-block-end: 20px;
}

.hero-title {
  color: var(--gunmetal-2);
  font-size: var(--fs-1);
  margin-block-end: 15px;
}

.hero-title .span {
  display: inline-block;
  color: var(--emerald);
  font-family: var(--ff-playfair-display);
  font-weight: var(--fw-700);
}

.hero-text {
  /* background-color: var(--pantone-green_90); */

  color: var(--white);
  font-size: var(--fs-3);
  line-height: 1.8;
  margin-block-end: 30px;
}





/*-----------------------------------*\
  #SERVICE
\*-----------------------------------*/

.service {
  /* background-image: url("../images/logo.png"); */
  background-color: var(--shamrock-green);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
  background-blend-mode: soft-light;
}
.service .section-title{ 
  color: var(--white);
  padding: 20px 0;
  
}

.service-list {
  display: grid;
  gap: 15px;
  
}

.item-icon {
  background-color: var(--white_25);
  max-width: max-content;
  padding: 20px;
  border-radius: 50%;
}

.service-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.item-title { color: var(--white); }



/*-----------------------------------*\
  #OFFERS
\*-----------------------------------*/

.offers-item {
  min-width: 100%;
  margin-inline: 15px;
  scroll-snap-align: start;
}

.offers-card {
  background-color: var(--mint-cream);
  padding: 30px 10px;
  text-align: center;
}

.offers-card .card-banner { margin-block-end: 20px; }

.offers-card .card-banner img {
  max-width: max-content;
  margin-inline: auto;
}

.offers-card .card-subtitle {
  color: var(--emerald);
  font-size: var(--fs-7);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  margin-block-end: 5px;
}

.offers-card .card-title {
  color: var(--gunmetal-2);
  margin-block-end: 12px;
}

.offers-card .btn {
  max-width: max-content;
  margin-inline: auto;
  --fs: var(--fs-8);
  --fw: var(--fw-400);
  --padding: 12px 25px;
}





/*-----------------------------------*\
  #PRODUCT
\*-----------------------------------*/

.product { padding-block-start: 0; }

.product .section-title { margin-block-end: 40px; }




/*-----------------------------------*\
  #CTA
\*-----------------------------------*/

.cta {
  background-image: url("../images/cta-bg-2.png");
  background-color: var();
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 50px 20px;
}
.cta .container {
  background-color: var(--pantone-green_90);
  padding: 50px 20px;
}

.cta .section-title {
  --fs-1: 2.5rem;
  margin-block-start: 20px;
  color: var(--gunmetal-1);
}

.cta-text {
  color: var(--white);
  font-size: var(--fs-4);
  margin-block: 15px 30px;
}

.cta .btn { margin-inline: auto; }










/*-----------------------------------*\
  #PARTNER
\*-----------------------------------*/

.partner { background-color: var(--emerald); }

.partner .section-subtitle { color: var(--white); }

.partner .section-title {
  color: var(--white);
  margin-block-end: 40px;
}


.partner-logo {
  max-width: max-content;
  margin-inline: auto;
}

.partner-item {
  display: flex;
  align-items: center;
  min-width: 100%;
  scroll-snap-align: center;
}





/*-----------------------------------*\
  #TESTIMONIALS
\*-----------------------------------*/

.testimonials { text-align: center; }

.testimonials .section-title { margin-block-end: 40px; }

.testi-item {
  margin-inline: 15px;
  min-width: 100%;
  scroll-snap-align: start;
}

.testi-card {
  background-color: var(--mint-cream);
  padding: 30px 20px;
}

.testi-card .card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.testi-card .card-avatar { border-radius: 50%; }

.testi-card .rating-wrapper { margin-block: 30px 20px; }

.testi-card .card-text {
  color: var(--battleship-gray);
  line-height: 1.7;
  font-size: var(--fs-6);
}

.testi-card .card-title {
  font-size: var(--fs-3);
  font-weight: var(--fw-500);
  margin-block: 25px 15px;
}

.testi-card .card-subtitle {
  color: var(--emerald);
  font-size: var(--fs-6);
  font-weight: var(--fw-500);
}





/*-----------------------------------*\
  #BLOG
\*-----------------------------------*/

.blog { padding-block-start: 0; }

.blog .section-title { margin-block-end: 40px; }

.blog-list {
  display: grid;
  gap: 30px;
}

.blog-card :is(.card-banner, .card-content) { padding: 30px; }

.blog-card .card-banner { background-color: var(--mint-cream); }

.blog-card .card-content { border: 2px solid var(--mint-cream); }

.blog-card .card-wrapper {
  display: flex;
  justify-content: space-between;
}

.blog-card .wrapper-item {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--fs-7);
  color: var(--battleship-gray);
}

.blog-card .wrapper-item ion-icon {
  color: var(--emerald);
  font-size: 1.5rem;
}

.blog-card .card-title {
  margin-block: 20px 30px;
  font-family: var(--ff-roboto);
  color: var(--russian-violet);
}

.blog-card .card-title a { transition: var(--transition-2); }

.blog-card .card-title a:is(:hover, :focus) { color: var(--emerald); }





/*-----------------------------------*\
  #LETSTALK
\*-----------------------------------*/

/* .letstalk { margin-block-start: 0; } */

.letstalk-card {
  background-color: var(--gunmetal-2);
  padding: 20px;
}

.letstalk-card .section-subtitle {
   font-size: var(--fs-3); }

.letstalk .section-title {
  color: var(--white);
  font-size: var(--fs-2);
  line-height: 1.6;
  margin-block-end: 40px;
}

.letstalk-form { 
  /* margin-inline: 20px;  */
  color: var(--white);
}
.letstalk label{
  padding-bottom: 10px;

}

.letstalk input,.letstalk textarea  {
  display: inline-block;
  background-color: var(--white);
  color: var(--battleship-gray);
  font-size: var(--fs-6);
  padding: 17px 25px;
  border-radius: 10px;
  margin-block-end: 10px;
}

.letstalk #message{
  width: 100%;
}

.letstalk .btn {
  width: 100%;
  justify-content: center;
}

.form-error-message a{
  display: inline;
  color: var(--emerald);
}
.form-error-message{
  color: red;
  text-align: center;
  /* font-weight: bold; */
  font-size: var(--fs-6);
  display: inline;
  padding: 10px;
}

.form-success-message{
  color: green;
  text-align: center;
  font-weight: bold;
  font-size: var(--fs-4);
  padding: 10px 0;
}





/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 480px screen
 */
 

@media (min-width: 480px) {

  /**
   * HERO
   */

  .hero {
    min-height: 450px;
    display: grid;
    align-items: center;
  }



  /**
   * SERVICE
   */

  .service-item { justify-content: start; }



  /**
   * OFFERS
   */

  .offers-card {
    text-align: left;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 30px 40px;
  }

  .offers-card .card-banner {
    margin-block-end: 0;
    order: 1;
  }

  .offers-card .btn { margin-inline: 0; }



  /**
   * PRODUCT
   */

  .product .grid-list {
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }

  .product-card .card-banner img { width: 100%; }

  

  /**
   * PARTNER
   */

  .partner-item { min-width: 33.33%; }



  /**
   * LETSTALK
   */

  .letstalk .section-title { --fs-3: 2.4rem; }

 

  
}





/**
 * responsive for larger than 569px screen
 */
 @media (max-width: 569px){
  /* only mobile */
  .hero-text {
    background-color: var(--pantone-green_90);
    color: var(--gunmetal-1);
    padding: 10px;
  }  
 }

@media (min-width: 569px) {

  /**
   * HERO
   */

  .hero {
    background-image: url("../images/main-t.jpg");
    height: 100%;
    background-position: center;
    background-size: cover;
    padding: 0;
  }

  .hero-title { --fs-1: 3.5rem; }



  /**
   * SERVICE
   */

  



  /**
   * CTA
   */

  .cta .section-subtitle { --fs-3: 3rem; }

  .cta .section-title { --fs-1: 3.5rem; }

  .cta-text { --fs-4: 2rem; }
 

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE FOR HOME
   */

  :is(.hero, .cta, .blog, .letstalk) .btn {
    --fs: 1.8rem;
    --fw: var(--fw-700);
    --padding: 18px 50px;
  }



  /**
   * HERO
   */

  .hero-title { --fs-1: 4.5rem; }

  .hero-title .span { font-size: 5.8rem; }

  .hero-banner { display: block;
  padding: 50px; }

  .hero .container {
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    align-items: center;
  }



  /**
   * SERVICE
   */
   .service-list { grid-template-columns: 1fr 1fr; }



  /**
   * OFFERS
   */

  .offers-list { gap: 30px; }

  .offers-item {
    min-width: calc(50% - 15px);
    margin-inline: 0;
  }



  /**
   * CTA
   */

  .cta { padding-block: 80px; }



  

  /**
   * PARTNER
   */

  .partner-item { min-width: 25%; }



  /**
   * TESTIMONIALS
   */

  .testi-item { min-width: 50%; }



  /**
   * BLOG
   */

  .blog-list {
    grid-template-columns: 1fr 1fr;
    column-gap: 25px;
  }



 
  /**
   * LETSTALK
   */
   .letstalk-flex{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex: 1;
  }

  .letstalk-item{ 
    width: 50%;
  }
  .letstalk label{
    display: inline-block;
  }
  .letstalk input,.letstalk textarea {
    padding: 8px;
    display: inline-block;
  }
  .letstalk-logo{
    display:flex;
    justify-content:center;
    align-items:center;
  }
  .letstalk-logo img{
    width:80%;
  }


}





/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * HERO
   */

  .hero-title { --fs-1: 5rem; }
  
  .service-list { grid-template-columns: repeat(4, 1fr); }



  /**
   * SERVICE
   */

  .service-item { gap: 20px; }

  .item-icon { padding: 25px; }

  .item-title { 
    --fs-4: 2.7rem;
    letter-spacing: 2px;
   }



  /**
   * OFFERS
   */

  .offers-card { padding-block: 70px; }

  .offers-card .card-title { --fs-4: 2.3rem; }

  .offers-card .btn {
    --fs-8: 1.5rem;
    --padding: 15px 25px;
  }



  /**
   * PRODUCT
   */

  .filter-btn {
    padding: 30px;
    gap: 15px;
  }

  .filter-btn > img { transform: scale(1.5); }

  .product .grid-list { grid-template-columns: repeat(4, 1fr); }



  /**
   * CTA
   */

  .cta { padding-block: 120px; }



  /**
   * TOP PRODUCT
   */

 



  /**
   * PARTNER
   */

  .partner-item { min-width: 20%; }



  /**
   * TESTIMONIALS
   */

  .testimonials .has-scrollbar { gap: 30px; }

  .testi-item {
    min-width: calc(33.33% - 20px);
    margin-inline: 0;
  }



  /**
   * BLOG
   */

  .blog-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * NEWSLETTER
   */

  .newsletter-card > * {
    max-width: 450px;
    margin-inline: auto;
  }

  .newsletter-card { padding-block: 150px; }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * HERO
   */

  .hero-title { --fs-1: 5.8rem; }

  .hero .container { grid-template-columns: 1fr 1fr; }



  /**
   * OFFERS
   */

  .offers-card .card-title {
    --fs-4: 2.8rem;
    margin-block: 20px;
  }


  /**
   * PARTNER
   */

  .partner {
    padding-block: 50px;
  }

  .partner-item { min-width: 16.66%; }

}