#tea-bread{
    background-image: url(../images/tea-bg.jpg);
}
#shop-bread{
    background-image: url(../images/coffee-beans-products.jpg);
}
#about-bread{
    background-image: url(../images/quality-assessment-1.jpg);
}
#contact-bread{
    background-image: url(../images/quality-eval.jpg);
}
#error-bread{
    background-image: url(../images/error.PNG);
}
#shipping-bread{
    background-image: url(../images/logistics.jpg);
}
#checkout-bread{
    background-image: url(../images/check-out.jpg);
}

