ul#team-list {
    text-align: center;
    gap: 20px;
}

.team-card img.card-avatar {
    width: 100%;
    border-radius: unset;
}

