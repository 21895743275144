.error-page{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 24px;
    margin: 20px 0;
}
.error-page .p{
    font-size: var(--fs-3)
}
.error-page a{
    color: var(--pantone-green_90)
}